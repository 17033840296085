<template>
<v-layout justify-center>
      <v-flex xs12 sm12 md12 lg12 class="mt-12">
        <v-card flat color="background">
          <!-- Push Contents In and Put Space Between Flex Items Using Grid List -->
          <v-container fluid grid-list-lg>
            <colaborators-toolbar title="Colaboradores" placeholderSearch="Buscar por nombre" @searchItem="searchItemData" @filterChanged="filterWasChanged"/>
            
            <v-layout row wrap v-if="itemsFiltered.length > 0">
              <!--  Create Cards Based on Objects in an Array -->
              <v-flex class="card-item" 
              v-for="item in itemsFiltered" v-bind="{ [`lg4 md4 xl4 sm6`]: true }" 
              :key="item._id"
              >
                <v-card flat @click.native="showItemInfo(item)" class="elevation-4">
                  <v-list color="cards" rounded two-line>
                    <v-list-item>
                      <v-list-item-avatar>
                        <v-badge
                          bottom
                          bordered
                          dot
                          :color="item.status == 0 ? 'error':'green'"
                          offset-x="14"
                          offset-y="14">

                          <v-avatar size="60">
                            <img
                              :src="require('@/assets/app/img-default-colaborador.jpg')"
                              alt="image"
                            />
                          </v-avatar>
                        </v-badge>
                        <!-- <img
                          src="https://picsum.photos/1920/1080?random"
                          alt="image"
                        > -->
                      </v-list-item-avatar>

                      <v-list-item-content>
                        <v-list-item-title><b>{{item.name}}</b></v-list-item-title>
                        <v-list-item-subtitle>{{item.email}}</v-list-item-subtitle>
                        <v-list-item-subtitle>{{item.role.description}}</v-list-item-subtitle>
                      </v-list-item-content>
                      
                      <v-list-item-action >
                        <v-menu bottom
                          origin="center center"
                          transition="scale-transition">
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                              icon
                                color="primary"
                                dark
                                v-bind="attrs"
                                v-on="on"
                              >
                                <unicon name="ellipsis-v" fill="#5A5A5A"></unicon>
                              </v-btn>
                            </template>
                            <v-list dense>
                              <v-list-item @click.stop="editItem(item)">
                                <v-list-item-title>Editar</v-list-item-title>
                              </v-list-item>
                              <v-list-item @click.stop="toggleActivationItem(item)">
                                <v-list-item-title>{{ item.status ? 'Bloquear': 'Reactivar' }}</v-list-item-title>
                              </v-list-item>
                              <v-list-item @click.stop="deleteItem(item)">
                                <v-list-item-title>Eliminar</v-list-item-title>
                              </v-list-item>
                            </v-list>
                          </v-menu>
                      </v-list-item-action>
                    </v-list-item>
                  </v-list>
                </v-card>
              </v-flex>
            </v-layout>
            <no-results v-else />

            <confirm-modal ref="confirm" @interface="componentConfirmResult" />
            <collaborator-info-drawer ref="drawerCollaborator" @editionClicked="editItem"/>

          </v-container>
          <floating-button @clickFloating="addNewCollaborator" icon="add" color="black"/>
        </v-card>
      </v-flex>

      <add-edit-collaborator-modal ref="addEditModal" :business="activeBusiness" :terminals="terminals"/>
    </v-layout>
</template>
<script>
  import axios from 'axios';
  import ColaboratorsToolbar from './Toolbars/ColaboratorsToolbar'
  import FloatingButton from './Toolbars/FloatingButton'
  import AddEditCollaboratorModal from './Dialogs/AddEditCollaboratorModal'
  import ConfirmModal from './Dialogs/ConfirmModal'
  import CollaboratorInfoDrawer from './Drawers/CollaboratorInfoDrawer'
  import NoResults from './NoResults'

  const REQUEST_ITEM_DELETE = 1;
  const REQUEST_ITEM_TOGGLE_ACTIVATE = 2;
  export default {
    components: {
      ColaboratorsToolbar,
      FloatingButton,
      AddEditCollaboratorModal,
      ConfirmModal,
      CollaboratorInfoDrawer,
      NoResults
    },
    data: () => ({
      filterSelected: '', //Todos los items
      collaborators: [],
      terminals: [],
      currentSearch: '',
      publicPath: process.env.VUE_APP_URL
    }),

    created () {
      this.initialize();
      this.loadListeners();
    },

    mounted() {
      //this.$store.dispatch('savePath', 'collaborators');
      this.$bus.$emit('selectCurrentMenuOption',5);
    },
    computed: {
      itemsFiltered(){
        let items = this.collaborators;
        if(this.filterSelected != ''){
          items = this.collaborators.filter(collaborator => collaborator.role.name == this.filterSelected);
        }
        return items;
      },
      activeBusiness(){
        return this.$store.getters.business;
      },
    },
    methods: {
      viewBusiness(business){
        this.$store.dispatch('saveBusiness', business)
      },
      filterWasChanged(selectedFilter){
        this.filterSelected = selectedFilter;
      },
      loadListeners () {
            this.$bus.$off('refreshCollaboratorsList');

            this.$bus.$on('refreshCollaboratorsList', (isLoading) => {
              this.listCollaborators();
            });
      },
      showItemInfo(item){
        this.$refs.drawerCollaborator.show(item);
      },
      deleteItemConfirmed(item_id, isConfirmated){
        if(isConfirmated){
          this.$bus.$emit('toggleLoading', true);
          axios.post('collaborators/delete',{item_id}).then(response => {
              this.$bus.$emit('toggleLoading', false);
              this.listCollaborators();
          }).catch(err => {

          });
        }
      },
      toggleActivationItemConfirmed(item_id, isConfirmated){
        if(isConfirmated){
          this.$bus.$emit('toggleLoading', true);
          axios.patch('collaborators/toggleActivation',{item_id}).then(response => {
              this.$bus.$emit('toggleLoading', false);
              this.listCollaborators();
          }).catch(err => {

          });
        }
      },
      componentConfirmResult(isConfirmated, item_id, component){
        switch (component) {
              case REQUEST_ITEM_DELETE :
                this.deleteItemConfirmed(item_id, isConfirmated);
              break;
              case REQUEST_ITEM_TOGGLE_ACTIVATE :
                this.toggleActivationItemConfirmed(item_id, isConfirmated);
              break;
          }
      },
      editItem(item){
        const itemRow = {...item};
        this.$refs.addEditModal.edit(itemRow);
      },
        deleteItem(item){
          let data = {
              title: "Eliminar",
              message: `¿Deseas eliminar a <<b>${item.name}</b>>? No podrás deshacer la acción.`,
              confirmText: "Si, Eliminar"
          }
          this.$refs.confirm.open(data, REQUEST_ITEM_DELETE, item._id);
        },
        toggleActivationItem(item){
          const message = item.status ? `<<b>${item.name}</b>> no podrá ingresar a la aplicación móvil. ¿Deseas continuar?`
          : `<<b>${item.name}</b>> podrá usar nuevamente la aplicación. ¿Deseas continuar?`;
          let data = {
              title: item.status ? "Bloquear colaborador" : "Reactivar colaborador",
              message: message,
              confirmText: item.status ? "Si, Bloquear" : "Si, Reactivar"
          }
          this.$refs.confirm.open(data, REQUEST_ITEM_TOGGLE_ACTIVATE, item._id);
        },
        addNewCollaborator(){
          this.$refs.addEditModal.open();
        },
        searchItemData(search){
          this.currentSearch = search;
          this.listCollaborators();
        },
        listCollaborators(){
          let me=this;
          const userData = this.$store.state.user
          const search = this.currentSearch;
          this.$bus.$emit('toggleLoading', true);

          if(userData.role == "admin"){

            axios.get('users/query?_id='+userData._id)
              .then(function (responseUser){
                const businessId = responseUser.data.business ;
                axios.get(`collaborators/list/${businessId}?search=${search}`).then(response => {
                  me.collaborators = response.data;
                  me.$bus.$emit('toggleLoading', false);
                }).catch(err => {});
            })

          }else{

            const businessId = this.activeBusiness._id;
            axios.get(`collaborators/list/${businessId}?search=${search}`).then(response => {
              this.collaborators = response.data;
              this.$bus.$emit('toggleLoading', false);
            }).catch(err => {});

          }
        
        },
        listTerminals(){
            const businessId = this.activeBusiness._id;
            axios.get(`terminals/list/${businessId}?search=`).then(response => {
                this.terminals = response.data;
            }).catch(err => {

            });
        },
        initialize () {
            this.listCollaborators();
            this.listTerminals();
        },
    },
  }
</script>
<style scoped>
 .flex-empty div {
  width: 350px;
}
.card-item{
  min-width: 320px !important;
  /*flex-basis: initial !important;
  max-width: initial !important;*/
}
#card-container, .data-containe{
  height: 55vh;
  overflow: auto;
  width: 100%;
  padding-right: 0 !important;
  margin-right: 0 !important;
}
 .v-list-item__action{
  margin-left: 0 !important;
}
.item-title:hover{
  text-decoration: underline;
}
</style>