<template>
<v-layout justify-center>
      <v-flex xs12 sm12 md12 lg12 class="mt-12">
        <v-card flat color="background">
          <!-- Push Contents In and Put Space Between Flex Items Using Grid List -->
          <v-container fluid grid-list-lg>
            <refunds-toolbar 
              title="Reembolsos" 
              placeholderSearch="Buscar" 
              @searchItem="searchItemData" 
              @filterChanged="filterWasChanged"
              :date="date"
              :business="activeBusiness"
              />
            
            <v-layout row wrap white>
              <v-flex lg3 md3 xl3 sm3>
                <v-menu
                  ref="menu1"
                  v-model="menu1"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      :value="computedDateFormattedMomentjs"
                      readonly
                      class="input-datapicker-table"
                      persistent-hint
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="date"
                    no-title
                    color="primary"
                    @change="dateSelected"
                    @input="menu1 = false"
                  >
                  </v-date-picker>
                </v-menu>
              </v-flex>
              <v-flex lg12 md12 xl12 sm12>
                <v-data-table
                  :headers="headers"
                  :items="refunds"
                  :items-per-page="10"
                  item-key="transaction_id"
                  sort-by="transaction_id"
                  @click:row="rowClicked"
                >
                  <template v-slot:createdAt="{ item }">
                      {{ formattedDate(item.createdAt) }}
                  </template>
                  
                  <template v-slot:refund_method="{ item }">
                      {{ item.refund_method.name }}
                  </template>
                  <template v-slot:amount="{ item }">
                       <span :style="item.amount < 0 ? 'color:red;' : ''">{{ item.amount < 0 ? '-' : ''}} ${{ Math.abs(item.amount).toFixed(2) }}</span>
                  </template>
                  <template v-slot:tag_balance="{ item }">
                      <span :style="item.tag_balance < 0 ? 'color:red;' : ''">{{ item.tag_balance < 0 ? '-' : ''}} ${{ Math.abs(item.tag_balance).toFixed(2) }}</span>
                  </template>
                  <template v-slot:status="{ item }">
                      {{ item.status == 1 || item.refund_method.name == "Efectivo" ? 'Reembolsado' : 'Pendiente' }}
                  </template>
                  <template v-slot:actions="{ item }">
                    <v-btn
                        icon
                        color="primary"
                        dark
                         @click.stop="openComment(item)"
                      >
                        <unicon v-if="!item.comments" name="comment-add" fill="#000000"></unicon>
                        <unicon v-else name="comment-add" fill="#7954C2"></unicon>
                    </v-btn>
            
                  </template>
                </v-data-table>
              </v-flex>
            </v-layout>

            <comment-modal ref="commentModal" />
            <refund-info-drawer ref="drawerRefund" @toggleSwitch="toggleRefundDone"/>

          </v-container>
        </v-card>
      </v-flex>
    </v-layout>
</template>
<script>
  import axios from 'axios';
  import RefundsToolbar from './Toolbars/RefundsToolbar'
  import CommentModal from './Dialogs/CommentModal'
  import RefundInfoDrawer from './Drawers/RefundInfoDrawer'
  import NoResults from './NoResults'
  import moment from 'moment'
  import { format, parseISO } from 'date-fns'

  const REQUEST_ITEM_DELETE = 1;
  const REQUEST_ITEM_TOGGLE_ACTIVATE = 2;

  export default {
    components: {
      RefundsToolbar,
      CommentModal,
      RefundInfoDrawer,
      NoResults
    },
    data: (vm) => ({
      date: format(parseISO(new Date().toISOString()), 'yyyy-MM-dd'),
      menu1: false,
      filterSelected: '', //Todos los items
      methodType: 'all',
      statusFilter: 'all',
      collaborators: [],
      terminals: [],
      currentSearch: '',
      publicPath: process.env.VUE_APP_URL,
      headers: [
          {
            text: 'Creado',
            align: 'start',
            value: 'createdAt',
            groupable: false,
          },
          { text: 'Reembolso', value: 'refund_id', groupable: false},
          { text: 'Tag', value: 'tag_id' },
          { text: 'Usuario', value: 'user_name', groupable: false },
          { text: 'Email', value: 'user_email', groupable: false },
          { text: 'Método', value: 'refund_method', groupable: false },
          { text: 'Reembolso', value: 'amount', groupable: false },
          { text: 'Estatus', value: 'status', groupable: false },
          { text: 'Acciones', value: 'actions', sortable: false },
        ],
        refunds: [],
    }),

    created () {
      this.initialize();
      this.loadListeners();
    },

    mounted() {
      this.$bus.$emit('selectCurrentMenuOption',3);
    },
    computed: {
      computedDateFormattedMomentjs () {
        return this.date ? this.dateHelpers.formatInput(this.date) : ''
      },
      activeBusiness(){
        return this.$store.getters.business;
      },
    },
    methods: {
      toggleRefundDone(item_id){
          this.$bus.$emit('toggleLoading', true);
          axios.patch('refunds/toggleDone',{refund_id: item_id}).then(response => {
              this.$bus.$emit('toggleLoading', false);
              this.$refs.drawerRefund.updateStatus(response.data);
              this.listRefunds();
          }).catch(err => {

          });
      },
      rowClicked(row){
        this.showItemInfo(row);
      },
      openComment(item){
        this.$refs.commentModal.open(item);
      },
      formattedDate(date){
        //const fDate = moment(date).utcOffset(0).format('DD MMMM YYYY HH:mm:ss');
        //const fDate = moment(date).utcOffset(0).format('DD MMM YYYY');
        const fDate = this.dateHelpers.formatTable(date)
        return fDate;
      },
      dateSelected(date){
        this.listRefunds();
      },
      viewBusiness(business){
        this.$store.dispatch('saveBusiness', business)
      },
      filterWasChanged(filterSelected,filterValue){
        console.log(filterSelected);
        console.log(filterValue);
        if(filterSelected == 'statusFilter'){
          this.statusFilter = filterValue;
        }else{
          this.methodType = filterValue;
        }
        this.listRefunds();
      },
        loadListeners () {
              this.$bus.$off('refreshRefundsList');

              this.$bus.$on('refreshRefundsList', (isLoading) => {
                this.listRefunds();
              });
        },
        showItemInfo(item){
          this.$refs.drawerRefund.show(item);
        },
        deleteItemConfirmed(item_id, isConfirmated){
          if(isConfirmated){
            this.$bus.$emit('toggleLoading', true);
            axios.post('collaborators/delete',{item_id}).then(response => {
                this.$bus.$emit('toggleLoading', false);
                //this.listCollaborators();
            }).catch(err => {

            });
          }
        },
        toggleActivationItemConfirmed(item_id, isConfirmated){
          if(isConfirmated){
            this.$bus.$emit('toggleLoading', true);
            axios.patch('collaborators/toggleActivation',{item_id}).then(response => {
                this.$bus.$emit('toggleLoading', false);
                //this.listCollaborators();
            }).catch(err => {

            });
          }
        },
        editItem(item){
          const itemRow = {...item};
          this.$refs.addEditModal.edit(itemRow);
        },
        deleteItem(item){
          let data = {
              title: "Eliminar",
              message: `¿Deseas eliminar a <<b>${item.name}</b>>? No podrás deshacer la acción.`,
              confirmText: "Si, Eliminar"
          }
          this.$refs.confirm.open(data, REQUEST_ITEM_DELETE, item._id);
        },
        toggleActivationItem(item){
          const message = item.status ? `<<b>${item.name}</b>> no podrá ingresar a la aplicación móvil. ¿Deseas continuar?`
          : `<<b>${item.name}</b>> podrá usar nuevamente la aplicación. ¿Deseas continuar?`;
          let data = {
              title: item.status ? "Bloquear colaborador" : "Reactivar colaborador",
              message: message,
              confirmText: item.status ? "Si, Bloquear" : "Si, Reactivar"
          }
        },
        addNewCollaborator(){
          this.$refs.addEditModal.open();
        },
        searchItemData(search){
          this.currentSearch = search;
          this.listRefunds();
        },
        listRefunds(){
          let me=this;
          const userData = this.$store.state.user
          const search = this.currentSearch;
          this.$bus.$emit('toggleLoading', true);
          if(userData.role == "admin"){
            axios.get('users/query?_id='+userData._id)
            .then(function (responseUser){
              const businessId = responseUser.data.business ;
              axios.get(`refunds/list/${businessId}/${me.statusFilter}/${me.methodType}?search=${search}&date=${me.date}`).then(response => {
                me.refunds = response.data;
                me.$bus.$emit('toggleLoading', false);
            }).catch(err => {  });
            })


          }else{
            const businessId = this.activeBusiness._id;
            axios.get(`refunds/list/${businessId}/${this.statusFilter}/${this.methodType}?search=${search}&date=${this.date}`).then(response => {
                this.refunds = response.data;
                this.$bus.$emit('toggleLoading', false);
            }).catch(err => {  });
          }
        },
        initialize () {
            this.listRefunds();
        },
    },
  }
</script>
<style scoped>
.flex-empty div {
  width: 350px;
}
.card-item{
  min-width: 320px !important;
  /*flex-basis: initial !important;
  max-width: initial !important;*/
}
#card-container, .data-containe{
  height: 55vh;
  overflow: auto;
  width: 100%;
  padding-right: 0 !important;
  margin-right: 0 !important;
}
.v-list-item__action{
  margin-left: 0 !important;
}
.item-title:hover{
  text-decoration: underline;
}
</style>