<template>
  <div>
    <h1></h1>
  </div>
</template>

<script>
export default {
  name: 'CyphraWebOrders',

  data() {
    return { };
  },

  mounted() { },

  methods: { },
};
</script>