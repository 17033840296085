<template>
  <div>
    <h1></h1>
  </div>
</template>

<script>
  export default {
    name: 'CyphraWebCatalogue',
    data() {
      return { };
    },
    mounted() { },
    methods: { },
  };
</script>